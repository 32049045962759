.price {
    .content_wrapper {
        display: flex;
        
        main {
            width: 100%;

            h1 {
                margin-bottom: 30px;
                font-weight: 700;
                font-size: 2.5rem;
            }

            article {
                margin-bottom: 36px;
            }

            .block-notice {
                padding: 26px 199px 29px 38px;
                background-color: #F7F8FC;
                margin-bottom: 50px; 
                border-left: 7px solid #2E9BEA;
            }

            .level_wrapper {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 60px;
                justify-content: space-between;

                .card {
                    width: 30%;
                    padding: 23px 30px;
                    border: 1px solid #ECECEC;
                    margin-right: 30px;

                    &:nth-child(3n+3) {
                      margin-right: 0;
                    }

                    h3 {
                        font-size: 1.5rem;
                        font-weight: 500;
                        margin-bottom: 7px;
                    }

                    .subtitle {
                        font-size: 0.875rem;
                        font-weight: 500;
                        color: #181818;
                    }

                    .num {
                        color: #2E9BEA;
                        font-weight: 600;
                        font-size: 2rem;
                        margin-bottom: 59px;
                        margin-right: 7px;
                    }
                    
                    .details{
                        margin-bottom: 85px;
                        display: flex;
                        justify-content: space-between;

                        .info {

                            .text{
                                display: block;
                                margin-bottom: 7px;
                            }
                        }
                        .number {
                            display: block;
                            margin-bottom: 7px;
                        }
                    }
                }

                    .sign-level {
                        border: 2px solid #FF0000;
                        color: #3D3535;
                        font-weight: 800;
                        padding: 22.5px 47px 26.5px 46px;
                        text-decoration: none;
                        white-space: nowrap;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:hover,
                        &:focus {
                          background-color: #ff0000;
                          color: #fff;
                        }
                    }
               
            }

            .form {
                margin-bottom: 100px;
                background: $dark-purple;
                
                .form-content {
                    margin-bottom: 42px;
                   
                    h2 {
                        font-size: 1.75rem;
                        font-weight: 700;
                        margin-bottom: 15px;
                        color: $white;
                    }

                    span {
                        font-size: 1.125rem;
                        color: $white;
                        margin-bottom: 42px;
                    }
                }

                .inputs {
                    display: flex;
                    justify-content: space-between;

                    .input {
                        width: 31.4%;
                        height: 70px;
                        background-color: #fff;
                        border: none;
                        padding: 23px 26px;
                        margin-bottom: 18px;

                        .input-mail, .input-phone {
                            border: none;
                            &::placeholder {
                                color: #7E7E7E;
                                font-size: 1rem;
                                font-weight: 500;
                            }
                        }
                        }
                    .form-cta {
                        padding: 22.5px 26.5px;
                        color: $white;
                        text-decoration: none;
                        width: 349px;
                        height: 70px;
                    } 
                }
                  
                .policy {

                    .policy-label{
                        font-size: 0.87rem;
                        color: $light-grey;
                    }
                    
                  }
                }
            }
        }
    }
            

    @media (min-width: 800px) and (max-width: 1162px) {
      
        .price .content_wrapper main .level_wrapper .card {
          margin-right: 10px;

          h3 {
            font-size: 1.2rem;
          }

          .num {
            font-size: 1.5rem;
          }

          .sign-level {
            font-size: 14px;
          }
          
          .details {
            font-size: 12px;
          }
          &:nth-child(3n+3) {
            margin-right: 0;
          }
        }

        .reach__form-content .reach__form-heading {
          width: 100%;
        }
        
        .price .content_wrapper main .form {
          .reach__form-content {
            font-size: 0.9rem;
            .reach__form-heading {
              font-size: 1.2rem;
            }
          }

          .reach__inputs {
            justify-content: end;
            flex-wrap: wrap;

            .reach__input {
              width: 50%;
              margin-bottom: 20px;
            }

            .cta {
              width: 50%;
            }
          }
        }
      }

    @media (min-width: 641px) and (max-width: 960px) {
        .container {
          max-width: 900px;
        }
      
        .menu__list {
          display: none;
        }
        
        .price .content_wrapper main .level_wrapper .card {
          width: 45%;
          margin-right: 0;

          .sign-level {
            font-size: 14px;
          }
        }

        .price .content_wrapper main .form {
          .reach__form-content {
            font-size: 0.9rem;
            .reach__form-heading {
              font-size: 1.2rem;
            }
          }

          .reach__inputs {
            justify-content: end;
            flex-wrap: wrap;

            .reach__input {
              width: 100%;
              margin-bottom: 20px;
            }
            .cta {
              width: 100%;
            }
          }
        }



        .reach__form-content .reach__form-heading {
          width: 100%;
        }
      }
      
      
      @media (min-width: 320px) and (max-width: 640px) {
        
        .reach__form-content .reach__form-heading {
          width: 100%;
        }
      
        .price .content_wrapper main {
          
          width: 100%;
          margin: 0;
      
          
          .bread_crumbs {
            font-size: 0.75rem;
        }
      
          aside {
            display: none;
        }
      
          h1 {
            font-size: 1.75rem;
            margin-bottom: 19px;
      
          }
      
          p {
            font-size: 0.875rem;
      
          }
      
          h2 {
            font-size: 1.25rem;
          }

          .block-notice {
              padding: 10px 10px;

              p {
                  font-size: 0.875rem;
              }
          }

          .level_wrapper {
              flex-direction: column;
              margin: 0;

              .card {
                  width: 100%;

                  h3 {
                    font-size: 1rem;
                  }

                  p {
                      font-size: 0.875rem;

                      span {
                          font-size: 0.875rem;
                      }
                  }

                  .details {
                      margin-bottom: 45px;

                      .text, .number {
                          font-size: 0.75rem;
                      }
                  }
                  .sign-level {
                      font-size: 0.875rem;
                  }
              }
          }

          .form-content {

            span {
              font-size: 0.875rem;
          }
        }

        .inputs {
          flex-direction: column;

          .input {
            width: 100% !important;
            height: 0;

            .input-mail {
              &::placeholder {
              font-size: 0.875rem;
              }
            }
             .input-phone {
               &::placeholder {
               font-size: 0.875rem;
             }
            }
          }

          .form-cta {
            width: 100% !important;
            font-size: 0.875rem;
          }
        } 

        .policy {
          
          .policy-label {
            font-size: 0.75rem !important;
            width: 100%;
          } 
          
        }
               
          
        }
      
      }
  
                

        
          

           
    

