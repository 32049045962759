@import "~bootstrap/scss/bootstrap";
@import "variables.scss";
@import "header.scss";
@import "global.scss";
@import "footer.scss";
@import "interface.scss";
@import "menu.scss";
@import "sidebar_menu.scss";
@import "language_club.scss";
@import "media.scss";
@import "levels.scss";
@import "deutch_courses.scss";
@import "price.scss";
@import "sprach_club.scss";
@import "contacts.scss";
@import "side_form.scss";
@import "sale.scss";

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraBold.woff");
  font-weight: 800;
  font-style: normal;
}

.menu_wrapper {
  background-image: url("../img/menu-bg.png");
  background-color: #161330;
  padding: 1.12rem 0;

}

.section {
  &-levels {
    padding-top: 80px;
    padding-bottom: 114px;
  }

  &-title {
    &__heading {
      &-levels {
        margin-bottom: 40px;
      }
    }
  }
}

.cards {
  flex-basis: 31.8%;
  margin-bottom: 30px;
}

img {
  max-width: 100%;
}

.level {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    justify-content: space-between;

    .cards {
      background-color: #464181;
      overflow: hidden;

      .bg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        transition: 0.5s;
      }

      .level__mask {
        z-index: 1;
        position: absolute;
        top: 160px;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: 0.5s;

        .level__description-info {
          opacity: 0;
          transition: 0.5s;

          span {
            display: block;
            margin-right: 20px;
          }
        }

        .mask_content {
          padding: 35px 15px 18px 23px;
        }
      }

      .desc_info {
        font-size: 0.875rem;
        line-height: 1.35rem;
        display: block;
      }

      &:hover .bg {
        transition: 0.5s;
        filter: blur(5px);
      }

      &:hover .level__mask {
        transition: 0.5s;
        top: -10px;

        .level__description-info {
          opacity: 1;
          padding-bottom: 0;

          .level__info-price,
          .level__info-date {
            font-weight: 600;
          }
        }
      }

      &:first-child .bg {
        background-image: url(../img/first-level.png);
      }

      &:nth-child(2) .bg {
        background-image: url(../img/second-level.png);
      }

      &:nth-child(3) .bg {
        background-image: url(../img/third-level.png);
      }

      &:nth-child(4) .bg {
        background-image: url(../img/forth-level.png);
      }

      &:nth-child(5) .bg {
        background-image: url(../img/fifth-level.png);
      }

      &:nth-child(6) .bg {
        background-image: url(../img/sixth-level.png);
      }
    }
  }

  &__card {
    position: relative;
  }

  &__image {
    display: block;
  }

  &__description {
    color: $white;
    height: 284px;
    position: relative;

    &-title {
      font-size: 1.5rem;
      font-weight: 500;
      color: $white;
      margin-bottom: 8px;
    }

    &-text {
      font-size: 1rem;
      margin-bottom: 17px;
    }

    &--second {
      top: 1.5rem;
      // left: 0;
      bottom: 0;
    }

    &-paragraf {
      font-size: 0.87rem;
    }

    &__info {
      font-size: 0.87rem;
      font-weight: 600;
      margin-right: 37px;

      &-price {
        display: block;
      }

      &-number {
        display: block;
      }
    }
  }

  &__cta {
    width: 100%;
    padding: 12px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: #fff;
    }

    path {
      &:hover {
        fill: #fff;
      }
    }

    &-content {
      display: inline-block;
      margin-right: 10px;
      font-size: 1rem;
    }
  }

  &__description-blocks {
    display: flex;
    align-items: center;
    padding-top: 12px;
    margin-bottom: 9px;
  }
}

.reverse-cta {
  &_level {
    display: block;
    margin: 0 auto;
    padding: 20px 30px;
    margin-bottom: 127px;
  }
}

.section-advantages {
  .container {
    overflow-x: inherit;
  }
}

.section {
  &-advantages {
    background-color: #262349;
    padding-top: 12.37rem;
    padding-bottom: 7.8rem;
  }

  &-title {
    &__heading {
      &-advantages {
        margin-top: 5.31rem;
        margin-bottom: 3.5rem;
        color: $white;
      }
    }
  }
}

.advantages {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__card {
    width: 31.7%;
    margin-bottom: 2.93rem;

    &-img {
      width: 2.37rem;
      height: 2.37rem;
    }
  }

  &__icon {
    margin-bottom: 0.87rem;
    position: relative;
    padding: 21px 21px;
    display: inline-block;

    &::before {
      content: "";
      background-color: $dark;
      opacity: 0.3;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  &__text {
    font-size: 1rem;
    font-weight: 500;
    color: #cecbf2;
  }

  &__blocks {
    display: flex;
    justify-content: space-between;
    margin-top: -19.5rem;
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    background-color: #ff000080;
    width: calc(25% - 23px);
    padding: 55px 0 62px;

    &-number {
      display: block;
      font-size: 3.75rem;
      font-weight: 700;
      margin-bottom: -7px;
    }

    &-text {
      display: inline-block;
      margin-right: 7px;
      margin-left: 10px;
      font-size: 1.12rem;
      font-weight: 500;
    }
  }
}

.section-reviews {
  padding-top: 106px;

  .container_advanced {
    max-width: 1240px;
  }

  .swiper {
    padding: 20px;
  }

  .slider_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 11px;
    padding-left: 20px;
    padding-right: 20px;

    .slider_navigation {
      position: relative;
      display: flex;

      .swiper-button-next,
      .swiper-button-prev {
        position: relative;
        right: 0;
        margin-left: 24px;
        left: 0;

        &::after {
          content: '';
          width: 30px;
          height: 10px;
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;
          background-image: url(../img/slider_arrow.svg);
        }
      }

      .swiper-button-next {
        transform: rotate(180deg);
      }
    }
  }
}

.reviews {
  &__wrapper {
    .swiper-slide {
      display: flex;
      justify-content: space-between;
      border: 1px solid #ECECEC;
      box-sizing: border-box;
      box-shadow: 0px 0px 20px rgba(48, 45, 94, 0.1);
      padding: 26px 30px;

    }

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 95px;
  }

  &__cards {
    width: 36.56rem;

    &-card {
      display: flex;
      margin-bottom: 1.47rem;
    }

    .reviews__card-img {
      width: 62px;
      height: 62px;
      margin-right: 16px;
    }

    &-text {
      color: $review-grey;
      font-size: 0.87rem;
    }
  }

  &__author {
    &-name {
      font-size: 1.12rem;
      font-weight: 600;
      color: $light-black;
      margin-top: 9px;
      margin-bottom: 0;
    }
  }

  &__paragraf {
    color: $light-black;
    margin-bottom: 1.68rem;
    line-height: 29px;
    max-height: 200px;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

    &.active {
      max-height: 800px;
      transition: max-height 0.5s ease-in-out;
    }
  }

  &__buttons {
    .hide {
      display: none;
    }

    //   svg {
    //     path {
    //       &:hover {
    //         fill: #2E9BEA;
    //     }
    //   }
    // }
    &.active {
      .show {
        display: none;
      }

      .hide {
        display: inline-block;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__button {
    &--red {
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.87rem;
      color: $red;
    }
  }
}

.section {
  &-reach {
    padding: 11px ​60px;
  }
}

.reach {
  &__form {
    background: $dark-purple;
    margin-bottom: 106px;
    padding: 12px 59px 66px;

    &-heading {
      width: 57.12rem;
      font-size: 1.75rem;
      font-weight: 700;
      color: $white;
      margin-top: 2.37rem;
      margin-bottom: 10px;
      display: block;
    }

    &-content {
      font-size: 1.12rem;
      color: $white;
      margin-bottom: 39px;
    }
  }

  &__inputs {
    display: flex;
    margin-bottom: 1.12rem;
    width: 100%;
    justify-content: space-between;
  }

  &__input {
    background: $white;
    width: 31.4%;
    height: 70px;

    .input {
      width: 100%;
      padding: 1.33rem 3.8rem 1.62rem 1.56rem;
      height: 70px;
    }

    &-mail,
    &-phone {
      border: none;

      &::placeholder {
        color: #7e7e7e;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  &__policy {
    &-label {
      font-size: 0.87rem;
      color: $light-grey;
    }

    &-link {
      color: $light-grey;
    }
  }
}

.form {
  &__cta {
    padding: 22.5px 123px 26.5px 122px;
    height: 70px;
  }
}

.section {
  &-title {
    &__heading {
      &-details {
        margin-bottom: 40px;
      }
    }
  }
}

.contacts {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 102px;
    justify-content: space-between;

    .map {
      width: 57.5%;
      filter: grayscale(0.8);
    }

    .contacts_cta {
      width: 285px;
    }
  }

  &__details {
    width: 30%;

    &-content {
      display: block;
      color: $light-black;
      margin-bottom: 4px;
    }

    &-phones {
      margin-top: 19px;
      margin-bottom: 14px;
    }

    &--phone {
      display: block;
      text-decoration: none;
      color: $light-black;
    }

    &-mail {
      color: $light-black;
      text-decoration: $red;
      margin-bottom: 53px;
      margin-top: 22px;
      display: block;
    }
  }

  .contacts_cta {
    padding: 22.5px 82px 26.5px 81px;
  }
}

.bread_crumbs_block {
  margin-top: 30px;
  margin-bottom: 50px;

  a {
    text-decoration: none;
    font-weight: 500;
    font-size: 0.875rem;
    color: #85848e;
    position: relative;
    margin-right: 30px;

    &:not(a:last-child):after {
      position: absolute;
      top: calc(50% - 1px);
      right: -15px;
      border-radius: 50%;
      width: 4px;
      height: 4px;
      display: inline;
      content: "";
      background-color: #85848e;
    }
  }
}


.popup_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 55;
  display: none;

  &.active {
    display: flex;
  }

  .popup {
    width: 990px;
    padding: 50px;
    background-color: #29254B;
    background-image: url(../img/form_bg.jpg);
    color: #fff;
    position: relative;
    background-size: cover;

    h3 {
      font-weight: bold;
      font-size: 2.5rem;
      line-height: 3.0625rem;
      margin-bottom: 21px;
    }

    .description {
      margin-bottom: 39px;
      font-size: 1.125rem;
      display: block;
    }

    .form_policy {
      font-size: 0.875rem;
      color: #A29FBF;
    }

    form {
      margin-bottom: 18px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .cta {
        width: calc(50% - 15px);
        padding: 22px 0;
      }

      .label {
        width: calc(50% - 15px);
        margin-bottom: 28px;

        .input {
          width: 100%;
          padding: 23px;
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.25rem;
          color: #7E7E7E;
        }

        &:nth-child(3) {
          margin-bottom: 0;
        }
      }

    }

    .close_btn {
      border: none;
      background-color: transparent;
      position: absolute;
      top: 22px;
      right: 22px;
    }



  }

  &.popupConfirm .popup {
    width: 370px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .textContainer {
      margin-bottom: 30px;
    }

    .cta {
      padding: 12px 26px 13px;
    }

  }
}

.mobileMenu,
.mobileMenuBtn {
  display: none;
}

.popupPolicy .popup {
  overflow-y: scroll;
  max-height: 90vh;

}

.popupPolicy {
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  ::-webkit-scrollbar-button {
    background-color: #444;
    display: none;
  }

  ::-webkit-scrollbar-track {
    background-color: #444;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #666;
  }

  ::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #444;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-corner {
    background-color: #999;
  }

  ::-webkit-resizer {
    background-color: #666;
  }

  .textContainer {
    padding-right: 20px;
  }
}

.linkPolicy {
  text-decoration: underline;
  cursor: pointer;
}