.blocks {
  &-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.first_level .courses-link,
.second_level .courses-link,
.third_level .courses-link,
.forth_level .courses-link,
.fifth_level .courses-link,
.sixth_level .courses-link,
.language_club .language_club-link,
.sprach_club .language_club-link,
.price .price-link,
.sprach_club .sprach_club-link,
.courses .courses-link,
.contacts .contacts-link,
.sale .sale-link {
  .menu_svg {
    fill: #ff0000;
  }

  .menu__list-link {
    text-decoration: none;
    color: #ff0000;
    border-bottom: 2px solid #ff0000;
    padding-bottom: 38px;
  }
}

.sprach_club .menu .sprach_club_link,
.language_club .menu .language_club_link,
.courses .menu .deutch_courses_link,
.first_level .menu .first_level_link,
.second_level .menu .second_level_link,
.third_level .menu .third_level_link,
.forth_level .menu .forth_level_link,
.fifth_level .menu .fifth_level_link,
.sixth_level .menu .sixth_level_link,
.sale .menu .sale-advanced_link,
.sale .menu .sale-beginner_link {
  color: #2e9bea;
}

.menu_wrapper {
  .container {
    overflow: inherit;
  }
}

.menu {
  margin-top: 22px;

  &-logo {
    width: 100px;
    margin-right: 102px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 0.87rem;

    &-item:nth-child(1n + 2) {
      margin-left: 49px;
    }

    &-link {
      text-decoration: none;
      color: $white;

      &:hover {
        color: #ff0000;
      }
    }

    // &-item.active,
    // &-item:hover {
    //   & .menu__list-link {
    //     color: #FF0000;
    //   }

    //   &::after {
    //     position: absolute;
    //     bottom: 0;
    //     display: block;
    //     content: '';
    //     background-color: #FF0000;
    //     width: 100%;
    //     height: 2px;
    //   }
    // }
  }

  &__contacts {
    &-svg {
      transform: translate(-25px, 60px);
    }

    &-phone {
      font-weight: 600;
      font-size: 1.31rem;
      color: $white;
      text-decoration: none;
      display: block;
    }
  }

  .menu_svg {
    fill: #fff;
  }

  .dropdownLinks {
    position: absolute;
    width: 200px;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 25px 75px -25px rgba(0, 0, 0, 0.1);
    left: calc(50% - 100px);
    transform: scaleY(0);
    transform-origin: 0 0;
    top: 60px;

    z-index: 5;

    ul {
      padding: 0;
    }

    li {
      padding: 0;
      list-style: none;
    }

    a {
      padding: 20px 15px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #464646;
      text-decoration: none;
      display: block;

      &:hover {
        color: #2e9bea;
      }
    }

    &.active {
      top: 60px;
      transition: 0.2s;
      transform-origin: 0 0;
      opacity: 1;
      transform: scaleY(1);
    }
  }

  .dropdown {
    position: relative;

    &.active {
      &>a {
        color: #ff0000;
      }

      color: #ff0000;

      .menu_svg {
        fill: #ff0000;
        transform: rotate(180deg);
      }
    }
  }
}

.mobileMenu {
  z-index: 10;
  overflow: scroll;
  background-color: #fff;

  .list-social {
    display: flex;
    padding: 18px;
    border-top: 1px solid #F0F0F0;
  }

  .social_list-item {
    list-style-type: none;
    margin-right: 14px;

    .social_link {
      width: 29px;
      height: 29px;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      background-position: center;
    }

    .social_link-facebook {
      background-image: url("../img/facebook-icon-mobile.svg");
    }

    .social_link-vkontakte {
      background-image: url("../img/Vk-icon-mobile.svg");
    }

    .social_link-instagram {
      background-image: url("../img/ins-icon-mobile.svg");
    }
  }
}

.mobileMenuHeader {
  padding: 7px 25px;
  position: relative;
  padding-bottom: 12px;
  border-bottom: 1px solid #F0F0F0;

  .closeMobileMenu {
    position: absolute;
    top: 7px;
    right: 10px;
    background-color: transparent;
    border: none;
  }
}

.mobileMenuList {
  margin-bottom: 0;

  .dropdownLinks ul {
    padding: 0;
  }

  padding: 0;

  li,
  .dropdownLinks li {
    list-style: none;
    color: #181818;
    padding: 0;
  }

  .dropdownLinks {
    margin-top: 18px;
    display: none;

    li {
      margin-bottom: 16px;
    }
  }

  & .menu__list-link {
    color: #181818;
  }

  .menu__list-item {
    border-bottom: 1px solid #f0f0f0;
    padding: 25px;
    margin-left: 0;
    color: #181818;
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;

    a {
      border-bottom: none !important;
      text-decoration: none;
      color: #181818 !important;
      padding-bottom: 0 !important;
    }

    .runlink {
      text-transform: none;
      font-size: 0.8rem;
      font-weight: normal;
    }

    &.active {
      .dropdownLinks {
        display: block;
      }
    }
  }

  .menu__list {
    flex-direction: column;
  }

  .dropdown {

    .menu_svg {
      fill: #181818;
    }

    position: relative;

    &.active {
      &>a {
        color: #181818;
      }

      color: #181818;

      .menu_svg {
        fill: #181818;
        transform: rotate(180deg);
      }
    }
  }
}

.blocks-menu .mobileMenuBtn {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 25px;
  background-color: transparent;
  border: none;
  padding: 0;

  span {
    display: block;
    height: 1px;
    width: 100%;
    margin-bottom: 6px;
    background-color: #FFFFFF;
  }

  span:last-child {
    margin-bottom: 0;
  }
}

.blocks-menu .mobileMenu {
  z-index: 25;
  position: fixed;
  display: block;
  top: 0;
  right: 0;
  left: 100%;
  bottom: 0;
  padding-left: 250px;
  background-color: rgba(77, 39, 39, 0);
  transition: 0.4s;

  &.active {
    transition: 0.4s;
    background-color: rgba(0, 0, 0, 0.6);
  }

  display: flex;

  .mobileMenuWrapper {
    width: 100%;
    position: sticky;

    .mobileMenuStickWrap {
      background-color: #fff;
      min-height: 100vh;
    }

    .mobileMenuHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .mobileMenuFooter {
      padding: 25px;

      .mobileContacts {
        margin-bottom: 22px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

      }

      .email-link {
        color: #FF0000;
        font-size: 0.875rem;
        text-decoration: underline;
        margin-bottom: 11px;
      }

      .phone-link,
      .whatsapp-link {
        color: #181818;
        font-size: 1rem;
        font-weight: 500;
        text-decoration: none;
        margin-bottom: 11px;
        position: relative;
      }

      .whatsapp-link::after {
        display: block;
        content: "";
        width: 15px;
        height: 15px;
        background-image: url(../img/whatsapp.svg);
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        right: -22px;
        top: 3px;
        background-size: contain;
      }

    }
  }

  transition: 0.7s;

  &.active {
    left: -200px;
    transition: 0.7s;
  }
}