section {
  &-title {
    &__heading {
      font-size: 2.5rem;
      font-weight: 700;
      color: $black;
    }
  }
}

.cta {
  background-color: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 800;
  border: none;

  &:hover,
  &:focus {
    background-color: #ff3d00;
  }
}

.reverse-cta {
  display: flex;
  border: 2px solid #ff0000;
  justify-content: center;
  align-items: center;
  color: #3d3535;
  font-weight: 800;
  background-color: #fff;

  &:hover,
  &:focus {
    background-color: #ff0000;
    color: #fff;
  }
  &:hover .arrow-href::after {
    background-color: #fff;
  }
}

.arrow-href::after {
  content: "";
  display: block;
  width: 6px;
  height: 8px;
  background-color: #000;
  margin-top: 5px;
  margin-left: 20px;
  clip-path: polygon(100% 50%, 0 0, 0 100%);
}
// для выпадающего меню

.dropout {
  margin-bottom: 20px;
  ._select {
    display: none;
    color: #3c3c3c;
    position: relative;
    width: 100%;
    background-color: #fff;

    &[data-state="active"] {
      ._select__title {
        &::after {
          transform: rotate(180deg);
        }
      }

      ._select__content {
        opacity: 1;
        box-shadow: 0px 19px 35px rgba(45, 80, 105, 0.1);
        display: flex;
      }

      ._select__label + .__select__input + .__select__label {
        max-height: 40px;
        border-top-width: 1px;
      }
    }
  }
  header {
    width: 100%;
    border: 1px solid #EBEBEB;
    padding: 5px 10px;
    h3 {
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
  ._select__title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px 5px;
    border-radius: 5px;

    cursor: pointer;
    &::before{
      display: none;
    }
    &::after {
      content: "";
      transform: rotate(0deg);
      clip-path: polygon(0 0, 44% 68%, 100% 0);
      position: absolute;
      top: 50%;
      right: 16px;

      display: block;
      width: 8px;
      height: 6px;
      background-color: #333333;
    }
  }
  ._select__content {
    display: none;
    position: absolute;
    top: 46px;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #F5F5F5;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    transition: all 0.3s ease-out;

    opacity: 0;
    z-index: 8;
  }
  ._select__input {
    display: none;

    &:checked + label {
      background-color: #dedede;
    }
    &:disabled + label {
      opacity: 0.6;
      pointer-events: none;
    }
  }
  ._select__label {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    max-height: 0;
    padding: 25px 17px;
    transition: all 0.2s ease-out;

    cursor: pointer;

    overflow: hidden;

    & + input + & {
      border-top: 0 solid #c7ccd160;
    }

    &:hover {
      color: #2e9bea;
    }
  }
}
#popupForm .__select {
  padding: 5px 10px 5px 0px;
}
.__select {
  color: #3C3C3C;
  position: relative;
  width: 230px;
  background-color: #fff;
  width: calc(50% - 15px);

  &[data-state="active"] {
    .__select__title {
      &::before {
        transform: translate(-3px, -50%) rotate(-45deg);
      }

      &::after {
        transform: translate(3px, -50%) rotate(45deg);
      }
    }
    
    .__select__content {
      opacity: 1;
    }
    
    .__select__label + .__select__input + .__select__label {
      max-height: 40px;
      border-top-width: 1px;
    }
  }
}
.__select__title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px 16px;

  border-radius: 5px;
  
  cursor: pointer;

  &::before,
  &::after {
    content: "";

    position: absolute;
    top: 50%;
    right: 16px;

    display: block;
    width: 10px;
    height: 2px;

    transition: all 0.3s ease-out;

    background-color: #333333;

    transform: translate(-3px, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(3px, -50%) rotate(-45deg);
  }

}
.__select__content {
  position: absolute;
  top: 45px;
  left: -1px;
  right: -1px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  border: 1px solid #c7ccd1;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  
  transition: all 0.3s ease-out;

  opacity: 0;
  z-index: 8;
}
.__select__input {
  display: none;

  &:checked + label {
    background-color: #dedede;
  }
  &:disabled + label {
    opacity: 0.6;
    pointer-events: none;
  }
}
.__select__label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  max-height: 0;
  padding: 0 16px;
  
  transition: all 0.2s ease-out;

  cursor: pointer;
  
  overflow: hidden;

  & + input + & {
    border-top: 0 solid #C7CCD160;
  }

  &:hover {
    color: #2E9BEA;
  }
}