.first_level, 
.second_level, 
.third_level, 
.forth_level,
.fifth_level, 
.sixth_level {
  .content_wrapper {
    display: flex;

    main {
      width: 75%;
      margin-right: 30px;
      line-height: 1.6;

      h1 {
        margin-bottom: 30px;
        font-weight: 700;
        font-size: 2.5rem;
      }

      article {
        margin-bottom: 40px;
      }

      h2 {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 18px;
      }

      .lists {
        margin-bottom: 40px;
      }

      .sign {
        display: flex;
        align-items: center;
        margin-bottom: 70px;
        justify-content: space-between;

        .sign-cta {
          padding: 15px;
          background-color: #ff0000;
          color: #fff;
          font-weight: 800;
          text-decoration: none;

          &:hover,
          &:focus {
            background-color: #FF3D00;
          }
        }

        .info {
          display: flex;
          .sign-info {
            color: #3d3535;
            margin-right: 42px;
          
            .text{
              display: block;
              margin-bottom: 7px;
              font-weight: 600;
          }
        }
          .sign-details {
            font-weight: 400;

            .number {
              display: block;
              margin-bottom: 7px;
          }
        }
      }
    }

      .study-programms {
        background: #29254b;
        padding: 45px 40px;
        display: flex;
        align-items: center;
        margin-bottom: 100px;
        
        .info-details {
          width: 50%;
          margin-right: 60px;

          p {
            color: #fff;
            font-size: 1.25rem;
            margin-bottom: 23px;

            .detail {
              font-weight: 600; 
            }

            span {
              font-weight: bold;
            }
        }
      }
        .price-reverse_cta {
          display: flex;
          justify-content: center;
          color: #fff;
          font-weight: 800;
          white-space: nowrap;
          padding: 22.5px 18px 26.5px 18px;
          width: 348px;
          text-decoration: none;
          border: 2px solid #FF0000;

          &:hover,
          &:focus {
            background-color: #ff0000;
            color: #fff;
            }
            }
        }
    }

    aside {
      width: 23%;
    }
  }
}


@media (min-width: 961px) and (max-width: 1280px) {
  .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
 
  }
 
  .first_level .blocks-menu,
  .second_level .blocks-menu,
  .third_level .blocks-menu,
  .forth_level .blocks-menu,
  .fifth_level .blocks-menu,
  .second_level .blocks-menu {
    .menu__blocks-logo {
      width: 87px;
    }
 
    .menu__list .menu__list-item {
      &:nth-child(1n+2) {
        margin-left: 19px;
     }
   }
    
    
     .menu__contacts-phone {
      font-size: 1.267rem;
    }
  }
}

@media (min-width: 641px) and (max-width: 960px) {
  .container {
    max-width: 900px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .menu__list {
    display: none;
  }

  .first_level .content_wrapper main,
  .second_level .content_wrapper main,
  .third_level .content_wrapper main,
  .forth_level .content_wrapper main,
  .fifth_level .content_wrapper main,
  .sixth_level .content_wrapper main {
    
    width: 60%;

    .bread_crumbs {
      font-size: 0.82rem;
  }

    h1 {
      font-size: 2.1rem;
      margin-bottom: 19px;

    }

    p {
      font-size: 0.94rem;

    }

    .list-text {
      font-size: 0.94rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    h3 {
      font-size: 1rem;
    }

    .sign {
      flex-direction: column;
      margin-bottom: 40px;

      .sign-info {
        margin: 0;

        span .text {
          font-size: 0.75rem;
      }

        span .number {
          font-size: 0.75rem;
          white-space: nowrap;
      }
    }
    }

    .sign-cta {
        width: 100% !important;
        font-size: 0.875rem;
        margin-bottom: 20px;
      }

  .study-programms {
    flex-direction: column;
    
    .info-details {
      width: 100%;
      margin: 0;

      p {
        font-size: 0.875rem !important;
      }
    }
    .price-reverse_cta {
      width: 100%;
      font-size: 0.75rem;
      display: flex;
      justify-content: center;
    }
  } 
    
  }
  .first_level .content_wrapper aside,
  .second_level .content_wrapper aside,
  .third_level .content_wrapper aside,
  .forth_level .content_wrapper aside,
  .fifth_level .content_wrapper aside,
  .sixth_level .content_wrapper aside {
    width: 38%;
  }
}


@media (min-width: 320px) and (max-width: 640px) {
  .first_level .content_wrapper aside,
  .second_level .content_wrapper aside,
  .third_level .content_wrapper aside,
  .forth_level .content_wrapper aside,
  .fifth_level .content_wrapper aside,
  .sixth_level .content_wrapper aside {
    display: none;
  }
  
  
  .first_level .content_wrapper main,
  .second_level .content_wrapper main,
  .third_level .content_wrapper main,
  .forth_level .content_wrapper main,
  .fifth_level .content_wrapper main,
  .sixth_level .content_wrapper main {
    
    width: 100%;
    margin: 0;

    
    .bread_crumbs {
      font-size: 0.75rem;
  }

    h1 {
      font-size: 1.75rem;
      margin-bottom: 19px;

    }

    p, li {
      font-size: 0.875rem;

    }

    h2 {
      font-size: 1.25rem;
    }

    h3 {
      font-size: 1rem;
    }

    .sign {
      flex-direction: column;
      margin-bottom: 0;

      .sign-info {
        margin: 0;

        span .text {
          font-size: 0.75rem;
      }

        span .number {
          font-size: 0.75rem;
          white-space: nowrap;
      }
    }
    }

    .sign-cta {
        width: 100% !important;
        font-size: 0.875rem;
        margin-bottom: 20px;
      }

  .study-programms {
    flex-direction: column;
    padding: 15px;
    
    .info-details {
      width: 100%;
      margin: 0;

      p {
        font-size: 0.875rem !important;
      }
    }
    .price-reverse_cta {
      width: 100%;
      font-size: 0.75rem;
      display: flex;
      justify-content: center;
    }
  } 
    
  }

}