$red: #FF0000;
$white: #FFFFFF;
$grey: #F5F4FE;
$dark-grey: #C1C1C7;
$top-font: 'Montserrat', 'san-serif';
$indigo: #908F97;
$black: #3D3535;
$dark-purple: #262349;
$light-purple: #AD94DD;
$dark:#161330;
$light-grey: #A29FBF;
$light-black: #181818;
$review-grey: #818181;
$level: #464181;




