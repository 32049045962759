.footer {
  background-color: $dark;

  .footer_wrapper_full {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .footer_wrapper {
    .caption_link {
      text-decoration: none;
    }
    h3 {
      text-transform: uppercase;
      color: $grey;
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 26px;
    }

    .footer_menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 17px;
      padding-bottom: 24px;
      &::after {
        border: 1px solid #ffffff;
        width: 100%;
      }

      .footer_logo {
        width: 110px;
      }

      .footer_contacts {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 11px;

        .footer-mail {
          margin-right: 88px;
          color: $red;
          font-size: 0.875rem;
          text-decoration: none;
          font-weight: 500;
        }

        .footer-reverse_cta {
          background-color: transparent;
          padding: 13.5px 41px 15.5px;
          color: #fff;
          border: 1px solid $red;
          margin-right: 91px;
          font-size: 0.875rem;
          &:hover,
          &:focus {
            background-color: #ff3d00;
          }
        }

        .footer_phones {
          display: flex;
          flex-direction: column;

          .footer-phone {
            color: $white;
            font-size: 1.25rem;
            text-decoration: none;
            font-weight: 600;
            display: block;
            position: relative;

            &.whatsapp::before {
              display: block;
              content: "";
              width: 15px;
              height: 15px;
              background-image: url(../img/whatsapp.svg);
              background-position: center;
              background-repeat: no-repeat;
              position: absolute;
              left: -22px;
              top: 7px;
              background-size: contain;
            }
          }
          .footer_svg {
            transform: translate(-25px, 60px);
          }
        }
      }
    }

    .footer_blocks {
      display: flex;
      padding-top: 53px;
      padding-bottom: 60px;

      .footer_chunk {
        margin-right: 96px;
      }

      .footer_block {
        .levels {
          column-count: 2;
          padding: 0;

          .level_list-item {
            list-style-type: none;
            &:nth-child(1n + 1) {
              margin-bottom: 4px;
            }
            .level_link {
              text-decoration: none;
              font-size: 0.81rem;
              color: #c1c1c7;
            }
          }
        }
      }
    }
    .club_list {
      padding: 0;

      .club_list-item {
        list-style-type: none;
        &:nth-child(1n + 1) {
          margin-bottom: 14px;
        }

        .club_link {
          text-decoration: none;
          font-size: 0.81rem;
          color: #c1c1c7;
        }
      }
    }

    .price {
      padding: 0;

      .price_list-item {
        list-style-type: none;
        &:nth-child(1n + 1) {
          margin-bottom: 14px;
        }

        .price_link {
          text-decoration: none;
          font-size: 0.81rem;
          color: #c1c1c7;
        }
      }
    }

    .list-social {
      padding: 0;
      display: flex;

      .social_list-item {
        list-style-type: none;
        margin-right: 14.8px;

        .social_link {
          width: 29px;
          height: 29px;
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          background-position: center;
        }

        .social_link-facebook {
          background-image: url("../img/facebook-icon.svg");
        }

        .social_link-vkontakte {
          background-image: url("../img/Vk-icon.svg");
        }

        .social_link-instagram {
          background-image: url("../img/ins-icon.svg");
        }

        .social_link-content {
          position: absolute;
          width: 1px;
          height: 1px;
          clip: rect(1px, 1px, 1px, 1px);
        }
      }
    }
  }
  .policy {
    display: flex;
    justify-content: space-between;
    padding-top: 23px;
    margin-bottom: 27px;

    .policy_text,
    .policy_link {
      color: $indigo;
      font-size: 0.75rem;
      text-decoration: none;
    }
  }
}
