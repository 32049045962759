.courses {
  .content_wrapper {
    display: flex;

    main {
      width: 75%;
      margin-right: 30px;

      h1 {
        margin-bottom: 35px;
        font-weight: 700;
        font-size: 2.5rem;
      }

      .course_img {
        margin-bottom: 40px;
        img {
          display: block;
          max-width: 100%;
      }
    }

      article {
        margin-bottom: 40px;
      }

      h2 {
        font-size: 1.75rem;
        font-weight: 600;
        margin-bottom: 35px;
      }

      .card {
        padding: 19px 25px;
        border: 1 px solid #ececec;
        &:nth-child(1n+1) {
          margin-bottom: 20px;
        }

        .card_body {
          display: none;
        }

        &.active {

          h3 {
            color: #2E9BEA;
          }

          .first, .second, .third, .forth, .fifth, .sixth {
            transform: rotate(180deg);

            .down-arrow {
              fill: #2E9BEA;
          }
          }

          .card_body {
            display: block;
          }
        }

        header {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h3 {
            font-weight: 600;
            font-size: 1.25rem;
          }
      }

        p {
          margin-bottom: 29px;
      }

      .sign {
        display: flex;
        justify-content: space-between;
        margin-bottom: 48px;

        .sign-cta {
          padding: 22.5px 34px 26.5px 33px;
          background-color: #ff0000;
          color: #fff;
          font-weight: 800;
          text-decoration: none;
          width: 254px;

          &:hover,
          &:focus {
            background-color: #FF3D00;
          }
        }

        .info {
          display: flex;
          justify-content: space-between;
        
        .sign-info {
          color: #3d3535;
          margin-right: 42px;
          
          .text{
            font-weight: 600;

            &:nth-child(1n+1) {
              margin-bottom: 4px;
              display: block;
            }
          }
        }
        .sign-details {
          font-weight: 400;

          .number {

            &:nth-child(1n+1) {
              margin-bottom: 4px;
              display: block;
          }
          }
        }
      }
    }
    }

      .study-programms {
        background: #29254b;
        padding: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 100px;
        
        .info-details {
          width: 50%;
          margin-right: 60px;

          p {
            color: #fff;
            font-size: 1.25rem;
            margin-bottom: 23px;

            .detail {
              font-weight: 600; 
            }

            span {
              font-weight: bold;
            }
        }
      }
        .price-reverse_cta {
          display: flex;
          justify-content: center;
          color: #fff;
          font-weight: 800;
          white-space: nowrap;
          padding: 22.5px 18px 26.5px 18px;
          width: 348px;
          text-decoration: none;
          border: 2px solid #FF0000;

          &:hover,
          &:focus {
            background-color: #ff0000;
            color: #fff;
            }
            }
        }
    }

    aside {
      width: 23%;

      .side_form {
        padding: 22px 15px;
        background: #29254b;
        color: #fff;
        margin-bottom: 11px;
        position: relative;

        h4 {
          font-size: 1.125rem;
          font-weight: 700;
        }

        p {
          font-size: 0.875rem;
          font-weight: 400;
        }

        label {
          position: absolute;
        }

        input {
          width: 100%;
          background-color: #fff;
          border: none;
          padding: 18px 25px;
          margin-bottom: 20px;

          &::placeholder {
            color: #7e7e7e;
            font-weight: 500;
          }
        }

        .cta {
          margin-bottom: 20px;
          width: 100%;
        }

        .form-description {
          color: #a29fbf;
          font-size: 0.75rem;
          margin-bottom: 20px;
        }
      }
    }
  }

  .cta {
    background-color: #ff0000;
    padding: 18px 20px 21px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 800;
    border: none;

    &:hover,
    &:focus {
      background-color: #ff3d00;
    }
  }
}

.revert-cta {
  background-color: #fff;
  padding: 18px 20px 21px 20px;
  display: flex;
  border: 2px solid #ff0000;
  justify-content: center;
  align-items: center;
  color: #3d3535;
  font-weight: 800;

  &:hover,
  &:focus {
    background-color: #ff0000;
    color: #fff;
  }
  &:hover .arrow-href::after {
    background-color: #fff;
  }
}

.arrow-href::after {
  content: "";
  display: block;
  width: 6px;
  height: 8px;
  background-color: #000;
  margin-top: 5px;
  margin-left: 20px;
  clip-path: polygon(100% 50%, 0 0, 0 100%);
}



@media (min-width: 961px) and (max-width: 1280px) {
  .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
 
  }
 
  .courses .blocks-menu {
    .menu__blocks-logo {
      width: 87px;
    }
 
    .menu__list .menu__list-item {
      &:nth-child(1n+2) {
        margin-left: 19px;
     }
   }
     .menu__contacts-phone {
      font-size: 1.267rem;
    }
  }
}

@media (min-width: 641px) and (max-width: 960px) {
  .container {
    max-width: 900px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .menu__list {
    display: none;
  }

  .courses .content_wrapper main {
    
    width: 60%;

    .bread_crumbs {
      font-size: 0.82rem;
  }

    h1 {
      font-size: 2.1rem;
      margin-bottom: 19px;

    }

    p {
      font-size: 0.94rem;

    }

    .list-text {
      font-size: 0.94rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    h3 {
      font-size: 1rem;
    }

    .sign {
      flex-direction: column;
      margin-bottom: 40px;

      .sign-info {
        margin: 0;

        span .text {
          font-size: 0.75rem;
      }

        span .number {
          font-size: 0.75rem;
          white-space: nowrap;
      }
    }
    // // }

    // // .sign-cta {
    // //     width: 100% !important;
    // //     font-size: 0.875rem;
    // //     margin-bottom: 20px;
    // }
    }

  .study-programms {
    flex-direction: column;
    
    .info-details {
      width: 100%;
      margin: 0;

      p {
        font-size: 0.875rem !important;
      }
    }
    .price-reverse_cta {
      width: 100%;
      font-size: 0.75rem;
      display: flex;
      justify-content: center;
    }
  } 
    
  }
  .courses .content_wrapper aside {
    width: 38%;
  }
}


@media (min-width: 320px) and (max-width: 640px) {

  .courses .content_wrapper aside {
    display: none;
  }
  .courses .content_wrapper main {
    
    width: 100%;
    margin: 0;

    .bread_crumbs {
      font-size: 0.75rem;
  }

    aside {
      display: none;
  }

    h1 {
      font-size: 1.75rem;
      margin-bottom: 19px;

    }

    p {
      font-size: 0.875rem;

    }

    h2 {
      font-size: 1.25rem;
    }

    h3 {
      font-size: 1rem;
    }

    .info {
      margin-bottom: 20px;
      width: 100%;

      .sign-info {
        margin: 0;

        span .text {
          font-size: 0.75rem;
      }

        span .number {
          font-size: 0.75rem;
      }
    }
    }

    .sign-cta {
        width: 100% !important;
        font-size: 0.875rem;
      }

  .study-programms {
    flex-direction: column;
    
    .info-details {
      width: 100%;
      margin: 0;

      p {
        font-size: 0.875rem !important;
      }
    }
    .price-reverse_cta {
      width: 100%;
      font-size: 0.75rem;
    }
  } 
    
  }
  .__select {
    display: flex;
    justify-content: space-between;
    padding: 13px 20px 16px 15px;
    border: 1px solid #EBEBEB;

    .__select__title {
      font-size: 0.75rem;
      text-transform: uppercase;
    }

    .select__content {

      .__select__label {
        font-size: 0.75rem;
        &:hover {
          background-color: #2E9BEA;

          ;

        }
      }
    }
  }

}







