.sidebar_menu {
    ul {
        padding: 0;
        
        li {
            list-style-type: none;

            
            a {
                padding: 22px 25px;
                color: #29282F;
                font-size: 0.75rem;
                font-weight: 600;
                text-decoration: none;
                border: 1px solid #EBEBEB;
                border-bottom: none;
                display: block;
                text-transform: uppercase;
                position: relative;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    width: 3px;
                    top: 0;
                    bottom: 0;

                }
            }
            a.active,
            a:hover {
                &::before {
                    background-color: #2E9BEA;
                }
            }
            &:last-child a {
                border-bottom: 1px solid #EBEBEB;
            }
        }
    }
}



.sprach_club .sidebar_menu .sprach_club_link,
.language_club .sidebar_menu .language_club_link,
.courses .sidebar_menu .deutch_courses_link,
.first_level .sidebar_menu .first_level_link,
.second_level .sidebar_menu .second_level_link,
.third_level .sidebar_menu .third_level_link,
.forth_level .sidebar_menu .forth_level_link,
.fifth_level .sidebar_menu .fifth_level_link,
.sixth_level .sidebar_menu .sixth_level_link {
  color: #2e9bea;
  &::before {
      background-color: #2E9BEA;
  }
}

