.language_club {
    .content_wrapper {
        display: flex;
        
        main {
            width: 75%;
            margin-right: 30px;

            h1 {
                margin-bottom: 30px;
                font-weight: 700;
                font-size: 2.5rem;
            }

            article {
                margin-bottom: 40px;
            }

            h2 {
                font-size: 1.25rem;
                font-weight: 600;
                margin-bottom: 32px;
            }

            .causes {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 45px;

                .causes-item {
                    padding: 15px;
                    width: 50%;
                    border: 1px solid #ECECEC;

                    .number {
                        display: block;
                        color: #FF0000;
                        font-weight: 600;
                        margin-bottom: 13px;
                    }

                    h3 {
                        font-size: 1rem;
                        font-weight: 600;
                        margin-bottom: 10px;
                        line-height: 25.5px;
                        height: 53px;
                    }

                    &:nth-child(even) {
                        border-left: none;
                    }

                    &:nth-child(1),
                    &:nth-child(2) {
                        border-bottom: none;
                    }
                }
            }

            .subscribe {
                background: #29254B;
                padding: 45px 40px;
                display: flex;
                align-items: center;
                margin-bottom: 100px;

                p {
                    margin-right: 80px;
                    color: #fff;
                    font-size: 1.25rem;
                    font-weight: 600;
                }

                a {
                    background: #ff0000;
                    display: flex;
                    color: #fff;
                    font-weight: 800;
                    padding: 22px 25px;
                    width: 257px;
                    text-decoration: none;
                    white-space: nowrap;
                }

            }
        }

        aside {
            width: 23%;

            .side_form {
                padding: 22px 15px;
                background: #29254B;
                color: #fff;
                margin-bottom: 11px;
                position: relative;

                h4 {
                    font-size: 1.125rem;
                    font-weight: 700;
                }

                p {
                    font-size: 0.875rem;
                    font-weight: 400;
                }

                label {
                    position: absolute;
                }

                input {
                    width: 100%;
                    background-color: #fff;
                    border: none;
                    padding: 18px 25px;
                    margin-bottom: 20px;

                    &::placeholder {
                        color: #7E7E7E;
                        font-weight: 500;
                    }
                }

                .cta {
                    margin-bottom: 20px;
                    width: 100%;
                }

                .form-description {
                    color: #A29FBF;
                    font-size: 0.75rem;
                    margin-bottom: 20px;
                }
            }
        }
        

        .club-info {
            margin-bottom: 60px;
        }
    }
}


@media (min-width: 641px) and (max-width: 960px) {
  .container {
    max-width: 900px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .menu__list {
    display: none;
  }

  .language_club .content_wrapper main {
    
    width: 60%;

    .bread_crumbs {
      font-size: 0.82rem;
  }

    h1 {
      font-size: 2.1rem;
      margin-bottom: 19px;

    }

    p {
      font-size: 0.94rem;

    }

    .list-text {
      font-size: 0.94rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    .causes .causes-item {
      width: 100%;

      &:nth-child(even) {
        border-left: 1px solid #ececec;
      }
    }

    h3 {
      font-size: 1rem;
    }

    .club-info {
      font-size: 0.875rem;
    }

    .subscribe {
      display: flex;
      flex-wrap: wrap;

      .cta {
        width: 100%;
        font-size: 0.94rem;
      }
    }
  
  }

  .language_club .content_wrapper aside {
    width: 38%;
  }
}
  
  
  @media (min-width: 320px) and (max-width: 640px) {
  
    .language_club .content_wrapper aside {
      display: none;
    }
    
    .language_club .content_wrapper main {
      
      width: 100%;
      margin: 0;
  
      
      .bread_crumbs {
        font-size: 0.75rem;
    }
  
  
      h1 {
        font-size: 1.75rem;
        margin-bottom: 19px;
  
      }
  
      p {
        font-size: 0.875rem;
  
      }
  
      h2 {
        font-size: 1.25rem;
      }
  
      h3 {
        font-size: 1rem;
      }
  
      .causes {
        flex-direction: column;
  
        .causes-item {
          width: 100%;
          padding-bottom: 20px;

          &:nth-child(even) {
            border-left: 1px solid #ececec;
          }
  
        }
      }

      .club-info {
          width: 100%;
      }
  
      .subscribe {
        flex-direction: column;
  
        p {
          font-size: 1.125rem;
          margin-right: 0;
        }

        .cta {
          width: 100% !important;
          font-size: 0.875rem;
        }
      }
      
    }
  
  }