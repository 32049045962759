.contacts {
    .content_wrapper {
        display: flex;
        
        main {
            width: 100%;

            h1 {
                margin-bottom: 30px;
                font-weight: 700;
                font-size: 2.5rem;
            }

            article {
                margin-bottom: 70px;
            }

            .bold {
                font-weight: 600;

                .red-link {
                     color: $red;
                     text-decoration-color: $red;
                     font-weight: 600;
                    }
                }

            .contacts-info {
                display: flex;
                justify-content: space-between;
                margin-bottom: 70px;

                .contacts_detail {
                    padding-top: 30px;
                    width: 30%;

                    .adress {
                        margin-bottom: 20px;

                        span {
                            display: block;
                        }
                    }

                    .contact_phones {
                        margin-bottom: 58px;

                        .contact-phone {
                            color: #181818;
                            text-decoration: none;
                            display: block;
                            margin-bottom: 2px;

                            &:nth-child(even) {
                                margin-bottom: 20px;
                            }
                        }
                        .contact-mail {
                            color: $red;
                            text-decoration-color: $red;
                        }
                    }


                    address .list-social {
                        padding: 0;
                        display: flex;
                  
                        .social_list-item {
                          list-style-type: none;
                          margin-right: 14.8px;
                  
                          .social_link {
                            width: 29px;
                            height: 29px;
                            background-repeat: no-repeat;
                            background-size: contain;
                            display: block;
                            background-position: center;
                  
                          .social_link-facebook {
                            background-image: url("../img/facebook-icon.svg"); 
                          }
                  
                          .social_link-vkontakte {
                            background-image: url("../img/Vk-icon.svg");
                          }
                  
                          .social_link-instagram {
                            background-image: url("../img/facebook-icon.svg");
                          }
                  
                          .social_link-content {
                            position: absolute;
                            width: 1px;
                            height: 1px;
                            clip: rect(1px, 1px, 1px, 1px);
                          }
                        }}
                      } 
                }

                .map {
                    width: 57.5%;
                    filter: grayscale(0.8);
                  }
             }

   
            .form {
                margin-bottom: 100px;
                padding: 15px;
                background: $dark-purple;
                
                .form-content {
                    margin-bottom: 42px;
                   
                    h2 {
                        font-size: 1.75rem;
                        font-weight: 700;
                        margin-bottom: 15px;
                        color: $white;
                    }

                    span {
                        color: $white;;
                        font-size: 1.125rem;
                    }
                }

                .inputs {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .input {
                        width: 31.4%;
                        height: 70px;
                        background-color: #fff;
                        border: none;
                        margin-bottom: 18px;

                        input {
                          padding: 23px 26px;
                        }

                        .input-mail, .input-phone {
                            border: none;
                            &::placeholder {
                                color: #7E7E7E;
                                font-size: 1rem;
                                font-weight: 500;
                            }
                        }
                    }
                    .input_cta {
                        padding: 22.5px 123px 26.5px 122px;
                        background: $red;
                        color: $white;
                        width: 31.4%;
                    }
                }   
                .___select {
                    color: #3C3C3C;
                    position: relative;
                    background-color: #fff;
                    width: 31.4%;
                    height: 70px;
                   
                    &[data-state="active"] {
                      .___select__title {
                        &::before {
                          transform: translate(-3px, -50%) rotate(-45deg);
                        }
                  
                        &::after {
                          transform: translate(3px, -50%) rotate(45deg);
                        }
                      }
                      
                      .___select__content {
                        opacity: 1;
                      }
                      
                      .___select__label + .___select__input + .___select__label {
                        max-height: 70px;
                        border-top-width: 1px;
                        width: 100%;
                      }
                    }
                  }
                  .___select__title {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 8px 16px;
                  
                    border-radius: 5px;
                    
                    cursor: pointer;
                  
                    &::before,
                    &::after {
                      content: "";
                  
                      position: absolute;
                      top: 50%;
                      right: 16px;
                  
                      display: block;
                      width: 10px;
                      height: 2px;
                  
                      transition: all 0.3s ease-out;
                  
                      background-color: #333333;
                  
                      transform: translate(-3px, -50%) rotate(45deg);
                    }
                    &::after {
                      transform: translate(3px, -50%) rotate(-45deg);
                    }
                  
                  }
                  .___select__content {
                    position: absolute;
                    top: 67px;
                  
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                  
                    background-color: #ffffff;
                  
                    border: 1px solid #c7ccd1;
                    border-top: none;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    
                    transition: all 0.3s ease-out;
                  
                    opacity: 0;
                    z-index: 8;
                  }
                  .___select__input {
                    display: none;
                  
                    &:checked + label {
                      background-color: #dedede;
                    }
                    &:disabled + label {
                      opacity: 0.6;
                      pointer-events: none;
                    }
                  }
                  .___select__label {
                    display: flex;
                    align-items: center;
                    width: 31.7%;
                    height: 70px;
                    max-height: 0;
                    padding: 0 16px;
                    
                    transition: all 0.2s ease-out;
                  
                    cursor: pointer;
                    
                    overflow: hidden;
                  
                    & + input + & {
                      border-top: 0 solid #C7CCD160;
                    }
                  
                    &:hover {
                      color: #2E9BEA;
                    }
                  }

                    .policy-label{
                        width: 66%;
                        font-size: 0.87rem;
                        color: #A29FBF;
                        align-self: center;

                        .grey-link {
                            color: $light-grey;     
                        }
                    }
                    
                }
            }
        }
    }

    body .container {
      overflow: inherit;
    }
    
    @media (min-width: 961px) and (max-width: 1280px) {
      .container {
        max-width: 1200px;
        padding-left: 15px;
        padding-right: 15px;
     
      }
     
      .blocks-menu {
        .menu__blocks-logo {
          width: 87px;
        }
     
        .menu__list .menu__list-item {
          &:nth-child(1n+2) {
            margin-left: 19px;
         }
       }
         .menu__contacts-phone {
          font-size: 1.267rem;
        }
      }
    }
    
    @media (min-width: 641px) and (max-width: 960px) {
      .container {
        max-width: 900px;
        padding-left: 15px;
        padding-right: 15px;
      }
    
      .menu__list {
        display: none;
      }
    
      .contacts .content_wrapper main {
    
        .bread_crumbs {
          font-size: 0.82rem;
      }
    
        h1 {
          font-size: 2.1rem;
          margin-bottom: 19px;
    
        }
    
        p {
          font-size: 0.94rem;
    
        }
    
        h2 {
          font-size: 1.25rem;
        }
    
        h3 {
          font-size: 1rem;
        }  
        
        .contacts_detail {
          .adress {

              span {
                font-size: 0.94rem;
              }
          }

          .contact_phones {

              .contact-phone, 
              .contact-mail {
                font-size: 0.94rem;
              }
          }


          .list-social {
              padding: 0;
              display: flex;
        
              .social_list-item {
                list-style-type: none;
                margin-right: 14.8px;
        
                .social_link {
                  width: 24px;
                  height: 24px;
        
              }}
            } 
      }

        .form {
          margin-bottom: 50px;
          padding: 44px 30px;
        
          .inputs {
            flex-wrap: wrap;

            .___select,
            .input_cta {
              width: 47.7%;
              font-size: 0.94rem;
          }

            .___select__title {
              font-size: 0.94rem;
          }

            .input {
              width: 47.7%;

              .input-mail, 
              .input-phone {

                &::placeholder {
                  font-size: 0.94rem;
              }
          }
        }

          .policy-label {
            width: 100%;
            margin-top: 20px;
            font-size: 0.83rem;
          }
        }
      }
    }    
    }


    @media (min-width: 320px) and (max-width: 640px) {

      .contacts .content_wrapper main {
        .bread_crumbs {
          font-size: 0.75rem;
      }
        h1 {
            font-size: 1.75rem;
            margin-bottom: 19px;
        }

        h2 {
          font-size: 1.125rem !important;
        }

        p {
          font-size: 0.875rem !important;
    
        }

          .contacts-info {
            flex-direction: column;

            .contacts_detail {
              padding: 0;
              width: 100%;
              display: block;

              span {
                font-size: 0.875rem;
              }

              .contact-phone, .contact-mail {
                font-size: 0.875rem;
              }
            }

            .map {
              width: 100%;
          }
          }
            .form-content {

              span {
                font-size: 0.875rem !important;
            }
          }

          .inputs {
            flex-direction: column;

            .input {
              width: 100% !important;

              .input-mail {
                &::placeholder {
                font-size: 0.875rem;
                }
              }
               .input-phone {
                 &::placeholder {
                 font-size: 0.875rem;
               }
              }
              .___select__input {
                &::placeholder {
                font-size: 0.875rem;
                }
              }
            }

            .___select {
              width: 100%;
              margin-bottom: 20px;

              .___select__title {
                font-size: 0.875rem;
                text-transform: inherit;
              }

              .___select__label {
                font-size: 0.75rem;
              }
            }

            .input_cta {
              width: 100% !important;
              font-size: 0.875rem;
              margin-bottom: 20px;
            }
            
            .policy-label {
              font-size: 0.75rem;
              width: 100%;
            }         
           }        
    }

  }
