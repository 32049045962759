.header {
  padding-bottom: 30px;
  background-image: url("../img/header.jpg");
  background-color: #39233e;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  // min-width: 1200px;
  &::before {
    content: "";
    background-color: #000;
    opacity: 0.07;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  &__wrapper {
    position: relative;
  }
}
.hero {
  &__content {
    padding-top: 139px;
    width: 50.68rem;
    margin-right: 4.78rem;
  }
  &__title {
    font-size: 3.75rem;
    font-weight: 700;
    color: $white;
    margin-bottom: 24px;
  }
  &__text {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.7;
    color: $white;
    margin-bottom: 56px;
  }
  &__cta {
    padding: 22.5px 35px 26.5px 35px;
    margin-bottom: 166px;
  }
}
