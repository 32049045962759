.sprach_club {
  .content_wrapper {
    display: flex;

    main {
      width: 75%;
      margin-right: 24px;

      h1 {
        margin-bottom: 30px;
        font-weight: 700;
        font-size: 2.5rem;
      }

      .sprach_img {
        margin-bottom: 40px;
        img {
          display: block;
          max-width: 100%;
      }
    }

      article {
        margin-bottom: 40px;
      }

      .block-images {
        margin-bottom: 40px;
        
        .block-img {
          width: 45%;
          margin-right: 25px;
        }

      }

      h2 {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 32px;
      }
      
      .notice {
        padding: 26px 130px 29px 38px;
        border-left: 7px solid #2e9bea;
        background-color: #F7F8FC;
        margin-bottom: 70px; 
      }
        

      .causes {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 45px;

        .causes-item {
          padding: 15px;
          width: 50%;
          border: 1px solid #ececec;

          .number {
            display: block;
            color: #ff0000;
            font-weight: 600;
            margin-bottom: 13px;
          }

          h3 {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 10px;
            line-height: 25.5px;
            height: 53px;
          }

          &:nth-child(even) {
            border-left: none;
          }

          &:nth-child(1),
          &:nth-child(2) {
            border-bottom: none;
          }
        }
      }

      .subscribe {
        background: #29254b;
        padding: 45px 40px;
        display: flex;
        align-items: center;
        margin-bottom: 100px;

        p {
          margin-right: 80px;
          color: #fff;
          font-size: 1.25rem;
          font-weight: 600;
        }

        a {
          background: #ff0000;
          display: flex;
          color: #fff;
          font-weight: 800;
          padding: 22px 25px;
          width: 257px;
          text-decoration: none;
          white-space: nowrap;
        }
      }
    }

    aside {
      width: 23%;

      .side_form {
        padding: 22px 15px;
        background: #29254b;
        color: #fff;
        margin-bottom: 11px;
        position: relative;

        h4 {
          font-size: 1.125rem;
          font-weight: 700;
        }

        p {
          font-size: 0.875rem;
          font-weight: 400;
        }

        label {
          position: absolute;
        }

        input {
          width: 100%;
          background-color: #fff;
          border: none;
          padding: 18px 25px;
          margin-bottom: 20px;

          &::placeholder {
            color: #7e7e7e;
            font-weight: 500;
          }
        }

        .cta {
          margin-bottom: 20px;
          width: 100%;
        }

        .form-description {
          color: #a29fbf;
          font-size: 0.75rem;
          margin-bottom: 20px;
        }
      }
    }

    .club-info {
      margin-bottom: 60px;
    }
  }

  .cta {
    background-color: #ff0000;
    padding: 18px 20px 21px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 800;
    border: none;

    &:hover,
    &:focus {
      background-color: #ff3d00;
    }
  }
}

.revert-cta {
  background-color: #fff;
  padding: 18px 20px 21px 20px;
  display: flex;
  border: 2px solid #ff0000;
  justify-content: center;
  align-items: center;
  color: #3d3535;
  font-weight: 800;

  &:hover,
  &:focus {
    background-color: #ff0000;
    color: #fff;
  }
  &:hover .arrow-href::after {
    background-color: #fff;
  }
}

.arrow-href::after {
  content: "";
  display: block;
  width: 6px;
  height: 8px;
  background-color: #000;
  margin-top: 5px;
  margin-left: 20px;
  clip-path: polygon(100% 50%, 0 0, 0 100%);
}


@media (min-width: 641px) and (max-width: 960px) {
  .container {
    max-width: 900px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .menu__list {
    display: none;
  }

  .sprach_club .content_wrapper main {
    
    width: 60%;

    .bread_crumbs {
      font-size: 0.82rem;
  }

    h1 {
      font-size: 2.1rem;
      margin-bottom: 19px;

    }

    p {
      font-size: 0.94rem;

    }

    .list-text {
      font-size: 0.94rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    .block-images {
      .block-img {
        width: 100%;

        &:nth-child(1n+2) {
          margin-top: 20px;
        }
      }
    }

    .notice {
      padding: 26px 0 29px 38px;
      border-left: 7px solid #2e9bea;
      background-color: #F7F8FC;
      margin-bottom: 40px; 
    }

    .causes .causes-item {
      width: 100%;

      &:nth-child(even) {
        border-left: 1px solid #ececec;
      }
    }

    h3 {
      font-size: 1rem;
    }

    .club-info {
      font-size: 0.875rem;
    }

    .subscribe {
      display: flex;
      flex-wrap: wrap;

      .cta {
        width: 100%;
        font-size: 0.94rem;
      }
    }
  
  }

  .sprach_club .content_wrapper aside {
    width: 38%;
  }
}


@media (min-width: 320px) and (max-width: 640px) {

  .sprach_club .content_wrapper aside {
    display: none;
  }
  
  .sprach_club .content_wrapper main {
    
    width: 100%;
    margin: 0;

    
    .bread_crumbs {
      font-size: 0.75rem;
  }


    h1 {
      font-size: 1.75rem;
      margin-bottom: 19px;

    }

    p {
      font-size: 0.875rem;

    }

    h2 {
      font-size: 1.25rem;
    }

    h3 {
      font-size: 1rem;
    }

    .block-images {
      display: flex;
      flex-wrap: wrap;

      .block-img {
        width: 100%;
        display: block;
        margin-bottom: 20px;

        // &:nth-child(1n+1) {
        //   margin-bottom: 20px;
        // }
      }
    }

    .causes {
      flex-direction: column;

      .causes-item {
        width: 100%;
        padding-bottom: 20px;

      }
    }

    .notice {
      padding: 10px 10px;
      margin-bottom: 30px;
    }

    .subscribe {
      flex-direction: column;

      p {
        font-size: 1.125rem;
        margin-right: 0;
      }
    }

    .sprach_cta {
      width: 100% !important;
      font-size: 0.875rem;
    }


    
  }

}