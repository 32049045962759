html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #000;
  box-sizing: border-box;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 0;
  
  overflow-x: hidden;
}


.cta {
  background-color: #ff0000;
  padding: 22px 36px 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 800;
  border: none;
  box-sizing: border-box;

  &:hover,
  &:focus {
      background-color: #FF3D00;
  }
}

.index_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 3rem;
  color: #3D3535;
}


input.masked,
.shell span {
  font-size: 16px;
  font-family: monospace;
  padding-right: 10px;
  background-color: transparent;
  text-transform: uppercase;
}