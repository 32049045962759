@media (min-width: 961px) and (max-width: 1280px) {
 .header__wrapper,
 .section-levels,
 .section-advantages,
 .section-reviews,
 .section-reach,
 .footer_wrapper_full {
   max-width: 1200px;
   padding-left: 15px;
   padding-right: 15px;

 }

 .header__wrapper .blocks-menu {
   .menu__blocks-logo {
     width: 87px;
   }

   .menu__list .menu__list-item {
     &:nth-child(1n+2) {
       margin-left: 19px;
    }
  }
   
   
    .menu__contacts-phone {
     font-size: 1.267rem;
   }
 }

 .header .header__wrapper {

  .hero__content {
    width: 70%;

    .hero__title {
      font-size: 3.19rem;
 }

    .hero__text {
      font-size: 1.18rem;
      overflow: inherit;
}

    .hero__cta {
      font-size: 0.94rem;
}
}
 }

 .footer_wrapper_full .footer_wrapper {

  .footer_menu {
    .footer_logo {
      width: 106px;
    }

    .footer_phones .footer-phone {
      font-size: 1.17rem;
    }
  }

  .footer_blocks {
    flex-wrap: wrap;
    justify-content: space-between;

    .footer_block,
    .language_club,
    .price,
    .block-socials {
      width: 40%;
      margin: 0;
    }

    .price {
      .price_list {
        padding: 0;
      }
    }
  }
}
.policy {
  padding-left: 15px;
  padding-right: 15px;
}
}


@media (min-width: 641px) and (max-width: 960px) {
 .header__wrapper,
 .section-levels,
 .section-advantages,
 .section-reviews,
 .section-reach,
 .footer_wrapper_full {
   max-width: 900px;
   padding-left: 15px;
   padding-right: 15px;

    h2 {
      font-size: 1.875rem;
  }

 }

 .header__wrapper {

     .hero__content {
       width: 75%;

       .hero__title {
         font-size: 2.63rem;
    }

       .hero__text {
         font-size: 1.13rem;
         overflow: inherit;
   }

       .hero__cta {
         font-size: 0.94rem;
   }
}

 .menu_wrapper {
   .menu__blocks-logo {
     width: 74px;
   }
   .menu__contacts-phone {
     font-size: 1.13rem;
   }
 }
}

 .section-levels {
   padding-top: 40px;
  
  .level__wrapper {
    .cards {
      flex-basis: 47.7%;

      h3 {
        font-size: 1.32rem;
      }

      span {
        font-size: 0.94rem;
      }
 
    }
  }
 }
  .section-advantages {

    .section-advantages .advantages-blocks {
      margin-top: -18rem;

      .section-advantages .advantages-block {
        padding: 20px 20px;

        .section-advantages .advantages__block-number {
          font-size: 3.13rem;
      }
  
        .section-advantages.advantages__block-text {
          font-size: 1rem;
      }
      }
    }

    .advantages__card {
      width: 47.7%;

      .advantages__icon {
        padding: 18px 18px;

        .advantages__card-img {
          width: 33px;
        }
      }
    }
  }


  .section-reviews {
    padding-top: 56px;

      .reviews__author-name {
        font-size: 1rem;
        margin-top: 0;
    }

      .reviews__paragraf {
        font-size: 0.94rem;
    }
    .reviews__buttons {
      span {
        font-size: 0.82rem;

      }
    } 
  }

  .section-reach {
    .reach__form {
      margin-bottom: 56px;
      padding: 12px 39px 46px;

      .reach__form-heading {
        font-size: 1.437rem;
      }
  
      .reach__form-text {
        font-size: 1rem;
      }
      
      .reach__inputs {
        flex-wrap: wrap;
        .reach__input {
          width: 47.7%;
  
          .reach__input-mail {
            &::placeholder {
              font-size: 0.94rem;
            }
          }
          .reach__input-phone {
            &::placeholder {
              font-size: 0.94rem;
            }
          }
        }
      }
      .form__cta {
        font-size: 0.82rem;
        width: 100%;
        margin-top: 20px;
      }
  
      .reach__policy {
        span {
          font-size: 0.82rem;
        }
      }
    }

    .contacts__details {
      
      h2 {
        margin-bottom: 20px;
      }

      span {
        font-size: 0.94rem;
      }
      
      .contacts__details-phones {
        margin-bottom: 10px;
 
        .contacts__details--phone {
          font-size: 0.94rem;
      }
        .contacts__details-mail {
          margin-bottom: 33px;
      }
    }
     .contacts_cta {
        font-size: 0.94rem;
        width: 100%;
        white-space: nowrap;
    }
  }
  }
    .footer .footer_wrapper {

        h3 {
          font-size: 0.83rem;
        }
        .footer_menu {
          .footer_logo {
            width: 103px;
          }
    
          .footer-mail, .footer-reverse_cta {
            display: none;
          }
    
          .footer_contacts .footer_phones {
            .footer-phone {
              font-size: 1.06rem;
            }
          }
        }
  
        .footer_blocks {
          flex-wrap: wrap;
          justify-content: space-between;
  
          .footer_block,
          .language_club,
          .price,
          .block-socials {
            width: 40%;
            margin: 0;
          }

          address .social-link {
            width: 26px;
            height: 26px;
      } 
        }
        .price {
          .price_list {
            padding: 0;
          }
        }
      }
      .policy {
        padding-left: 15px;
        padding-right: 15px;
      }
  }

@media (min-width: 320px) and (max-width: 640px) {
  aside {
    display: none;
  }
  .contacts.menu__contacts {
    display: none;
  }
  .container {
    max-width: 600px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .header {
    background-image: url(../img/header-mobile.jpg);
    padding-top: 0.37rem;
    padding-bottom: 2rem;
    
  }

  div {
    .blocks-menu .mobileMenuBtn {
      display: flex;
    }
  }

  .button {
    &--outline {
      width: 100%;
      text-align: center;
    }
  }
  .menu {
    display: none;
    &__blocks {
      &-logo {
        width: 3rem;
      }
    }
  }

  .hero {
    &__title {
      font-size: 1.5rem;
      margin-bottom: 0.56rem;
    }
    &__text {
      font-size: 1rem;
      margin-bottom: 2rem;
    }
    &__content {
      width: 100%;
      padding-top: 0;
      margin-top: 74px;
    }
    &__cta {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .section {
    &-title {
      &__heading {
        font-size: 1.25rem;
        &-level {
          margin-bottom: 0.98rem;
        }
      }
    }
  }

  main .section {
    &-levels {
      padding-top: 23px;

      .level__wrapper .cards {
        width: 100%;
        height: 198px;

        .level__description {
          height: 198px;
        }

        .level__mask {
          top: 70px;
        }

        .desc_info {
          opacity: 0;
        }

        .level__description-blocks {
          opacity: 0;
        }
      }
      .reverse-cta_level {
        margin-bottom: 0;
      }
    }
  }

  .cards {
    margin-right: 0;
    &:nth-child(1n + 1) {
      margin-bottom: 0.62rem;
    }
  }
  main .level {
    &__wrapper {
      flex-direction: column;
      align-items: center;
    }
    &__image {
      width: 100%;
    }
    &__description {
      &-title {
        font-size: 1.12rem;
      }
      &-text {
        font-size: 0.87rem;
      }
      &-info,
      &-paragraf {
        display: none;
      }
      &__cta {
        display: none;
      }
      &--second {
        top: 12rem;
        left: 2rem;
      }
    }
  }

  .reverse-cta {
    width: 100%;
  }

  main .section {
    &-title {
      &__heading {
        &-advantages {
          margin-top: 0;
          margin-bottom: 2rem;
          line-height: 25px;
        }
      }
    }
  }

  .container .advantages {
    .section-title__heading {
      margin-top: 0;
    }
    &__blocks {
      flex-wrap: wrap;
      margin-bottom: 33px;
      margin-top: -260px;
    }
    &__block {
      width: 45%;
      margin-bottom: 1.25rem;

      padding: 23px 0;
      &-number {
        font-size: 2.5rem;
      }
      &-text {
        font-size: 0.81rem;
      }
    }

    &__wrapper {
      flex-direction: column;
    }
    &__card {
      width: 100%;
      &:nth-child(1n + 1) {
        margin-bottom: 32px;
      }
    }
    &__text {
      font-size: 0.87rem;
    }
  }

  .section {
    &-reviews {
      padding-left: 0.94rem;
      padding-right: 2rem;
    }
    &-title {
      &__heading {
        &-reviews {
          margin-bottom: 1.12rem;
        }
      }
    }
  }
  .reviews {
    &__cards {
      &-first {
        width: 100%;
      }
      &-card {
        margin-bottom: 0.94rem;
      }
    }
    &__paragraf {
      margin-bottom: 0.94rem;
      font-size: 0.94rem;
    }
    &__button {
      &-content {
        font-size: 0.75rem;
      }
    }
  }
  .container .reach {
    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 2.87rem;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 10px;
      &-heading {
        font-size: 1.13rem;
        margin-bottom: 0.75rem;
        width: 100%;
      }
      &-text {
        font-size: 0.87rem;
        margin-bottom: 1.81rem;
      }
    }
    &__input {
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        height: 100%;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }

      &:nth-child(1n + 1) {
        margin-bottom: 1.25rem;
      }
      &-phone {
        &::placeholder {
          font-size: 0.87rem;
        }
      }

      .form_cta {
        width: 100%;
        padding: 1.09rem 5.56rem 1.28rem 5.5rem;
        font-size: 0.87rem;
      }
    }
    &__inputs {
      margin-bottom: 1.25rem;
      flex-direction: column;
    }
    &__policy {
      &-label {
        font-size: 0.75rem;
      }
    }
  }

  .section {
    &-title {
      &__heading {
        &-details {
          margin-bottom: 1.06rem;
        }
      }
    }
  }
  .contacts {
    h2 {
      font-size: 1rem;
    }
    &__details {
      &-content {
        font-size: 0.87rem;
        margin-bottom: 0.56rem;
      }
      &-phones {
        margin-bottom: 0.56rem;
      }
      &-mail {
        margin-bottom: 1.56rem;
      }
      &--phone {
        font-size: 0.87rem;
      }
    }
    .contacts_cta {
      width: 100%;
    }
  }

  .container .contacts__wrapper {
    flex-direction: column;

    .contacts__details {
      width: 100%;
      margin-bottom: 37px;

      .contacts_cta {
        width: 100%;
      }
    }

    .map {
      width: 100%;
    }
  }

  .footer_wrapper_full .footer_menu {
    padding-top: 0 !important;
    padding-bottom: 20px !important;
    .footer_logo {
      width: 97px;
      margin-right: 19px;
    }

    .footer_contacts {
      flex-direction: column-reverse;

      .footer-mail {
        font-size: 0.75rem !important;
        margin-right: 0 !important;
      }

      .footer-reverse_cta {
        display: none;
      }
    }
    .footer_phones {
      margin-bottom: 10px;
      padding-left: 35px;
      padding-top: 6px;
      .footer-phone {
        font-size: 0.875rem !important;
        margin-bottom: -1px;
      }
    }
  }

  .footer_blocks {
    padding-top: 22px !important;
    padding-bottom: 0 !important;
    .footer_block,
    .language_club,
    .price {
      display: none;
    }
    .block-socials {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 4px;

      h3 {
        margin-right: 37px;
        font-size: 0.75rem;
        margin-bottom: 0px;
      }
    }
  }
  body .footer .footer_wrapper .list-social .social_list-item .social_link {
    height: 17px;
    width: 17px;
  }

  .policy {
    flex-direction: column;
    padding-top: 16px !important;
    padding-bottom: 27px;

    .policy_text {
      font-size: 0.75rem;
      margin-bottom: 10px;
    }

    .policy_link {
      font-size: 0.75rem;
    }
  }
  .footer .footer_wrapper .footer_menu .footer_contacts .footer_phones .footer-phone.whatsapp:before {
    top: 2px;
  }


  div.popup_wrapper  {
    overflow: scroll;
    align-items: flex-start;

    div.popup {
      width: 85%;
      padding: 10px 30px;

      h3 {
        font-size: 1.2rem;
        margin-bottom: 0;
      }

      .description {
        font-size: 0.8rem;
        margin-bottom: 15px;
      }
      
      .form_policy {
        font-size: 0.7rem;
      }
    }
    #popupForm {
      flex-direction: column;
      .label,
      .__select,
      .sendFormBtn {
        width: 100%;
        margin-bottom: 18px;

        &.cta {
          margin-bottom: 0;
        }
      }

      input {
        padding: 10px;
      }

    }

  }
  .dropout ._select {
    display: flex;
  }

  .courses .content_wrapper main .card.active .card_body {
    .sign {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
  }

  .desktop_view {
    display: none !important;
  }
  .mobile_view {
    display: block ;

    &.info {
      display: flex !important;
      flex-direction: column;
      width: 100%;
      margin-bottom: 20px;
      
      .sign-info-mob {
        margin-bottom: 10px;
        .text {
          display: block;
          font-weight: bold;
        }
      }
    }
  }
}

.mobile_view {
  display: none  !important;
}
