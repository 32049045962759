.side_form {
    padding: 22px 15px;
    background: #29254b;
    color: #fff;
    margin-bottom: 11px;
    position: relative;

    h4 {
      font-size: 1.125rem;
      font-weight: 700;
    }

    p {
      font-size: 0.875rem;
      font-weight: 400;
    }

    label {
      position: absolute;
    }

    input {
      width: 100%;
      background-color: #fff;
      border: none;
      padding: 18px 25px;
      margin-bottom: 20px;

      &::placeholder {
        color: #7e7e7e;
        font-weight: 500;
      }
    }

    .cta {
      margin-bottom: 20px;
      width: 100%;
    }

    .form-description {
      color: #a29fbf;
      font-size: 0.75rem;
      margin-bottom: 20px;
    }
  }